import type { ColumnLibrary } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type ServerSideRowDataHelper from '@app/data-access/services/trading/positions/common/tree-grid/helpers/server-side-row-data-helper.abstract.class';
import type { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import {
  defaultColumn,
  accountCurrencyIdServerSide,
  quantity,
  longPosition,
  shortPosition,
  netPosition,
  longExposure,
  shortExposure,
  netExposure,
  combinedExposure,
  totalPNLToday,
  unrealizedPNL,
  monthToDatePNL,
  previousMonthPNL,
  quarterToDatePNL,
  yearToDatePNL,
  previousYearPNL,
  longPositionCost,
  shortPositionCost,
  netPositionCost,
  combinedPositionCost,
  valuationPrice
} from './position-columns';

export const createPositionsAccountColumnLibrary = (
  dataHelper?: ServerSideRowDataHelper<PositionRow>
): ColumnLibrary<TreeDataSlice<PositionRow>> => ({
  defaultColumn,
  columns: [
    longPosition,
    shortPosition,
    netPosition,
    accountCurrencyIdServerSide(dataHelper),
    longExposure,
    shortExposure,
    netExposure,
    combinedExposure,
    unrealizedPNL,
    totalPNLToday,
    monthToDatePNL,
    previousMonthPNL,
    quarterToDatePNL,
    previousMonthPNL,
    yearToDatePNL,
    previousYearPNL,
    longPositionCost,
    shortPositionCost,
    netPositionCost,
    combinedPositionCost,
    valuationPrice,
    (c) => quantity(c).hide()
  ]
});

export default createPositionsAccountColumnLibrary;
