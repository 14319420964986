import { inject, singleton } from 'tsyringe';
import { GetAuditStreamForInvestorOrderDocument } from '@oms/generated/frontend';
import type {
  AuditTrailInfoFragment,
  GetAuditStreamForInvestorOrderQuery,
  GetAuditStreamForInvestorOrderQueryVariables
} from '@oms/generated/frontend';
import { map } from 'rxjs';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';

@singleton()
export class InvestorOrderAuditTrailService {
  constructor(@inject(ApolloClientRPC) private apolloClient: ApolloClientRPC) {}

  public getAuditTrail$(investorOrderId: string, pollInterval = 5000) {
    return this.apolloClient
      .watchQuery<GetAuditStreamForInvestorOrderQuery, GetAuditStreamForInvestorOrderQueryVariables>({
        query: GetAuditStreamForInvestorOrderDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: investorOrderId
        }
      })
      .pipe(
        map((res) => {
          const results =
            (res.data.getAuditStreamForInvestorOrder as AuditTrailInfoFragment[]) ||
            ([] as AuditTrailInfoFragment[]);

          const sanitized = results.map(
            (r): OrderAuditTrail => ({
              id: `${r.aggregateId}-${r.aggregateVersion}`,
              eventTimestamp: r.timestamp,
              event: r.aggregateType,
              eventDetails: r?.payload,
              bidPrice: r?.marketdataSnapshot?.bidPrice || undefined,
              askPrice: r?.marketdataSnapshot?.askPrice || undefined,
              lastTradePrice: r?.marketdataSnapshot?.lastTradePrice || undefined,
              cumulativeVolume: r?.marketdataSnapshot?.cumulativeVolume || undefined
            })
          );

          return sanitized;
        })
      );
  }
}
