import { z } from 'zod';
import { createVersionedSchema } from '@oms/frontend-schemas';

enum OrderSideType {
  Btc = 'BTC',
  Buy = 'BUY',
  Exempt = 'EXEMPT',
  Sell = 'SELL',
  Short = 'SHORT'
}

const zOrderSideType: z.ZodType<OrderSideType> = z.enum([
  OrderSideType.Buy,
  OrderSideType.Sell,
  OrderSideType.Btc,
  OrderSideType.Exempt,
  OrderSideType.Short
]);
export enum OrderType {
  Limit = 'LIMIT',
  Market = 'MARKET'
}

const zOrderType: z.ZodType<OrderType> = z.enum([OrderType.Limit, OrderType.Market]);

enum TimeInForce {
  Close = 'CLOSE',
  Open = 'OPEN',
  Day = 'DAY',
  Duration = 'DURATION',
  Gtc = 'GTC',
  Gtd = 'GTD',
  Ioc = 'IOC',
  TifNotSupported = 'TIF_NOT_SUPPORTED'
}

const zTimeInForce: z.ZodType<TimeInForce> = z.enum([
  TimeInForce.Close,
  TimeInForce.Day,
  TimeInForce.Duration,
  TimeInForce.Gtc,
  TimeInForce.Gtd,
  TimeInForce.Ioc,
  TimeInForce.Open,
  TimeInForce.TifNotSupported
]);
enum OrderSettleType {
  Cash = 'CASH',
  Future = 'FUTURE',
  NextDay = 'NEXT_DAY',
  Regular = 'REGULAR',
  TPlus_2 = 'T_PLUS_2',
  TPlus_3 = 'T_PLUS_3',
  TPlus_4 = 'T_PLUS_4',
  TPlus_5 = 'T_PLUS_5',
  WhenIssued = 'WHEN_ISSUED'
}

const zOrderSettlementType: z.ZodType<OrderSettleType> = z.enum([
  OrderSettleType.Cash,
  OrderSettleType.Future,
  OrderSettleType.NextDay,
  OrderSettleType.Regular,
  OrderSettleType.TPlus_2,
  OrderSettleType.TPlus_3,
  OrderSettleType.TPlus_4,
  OrderSettleType.TPlus_5,
  OrderSettleType.WhenIssued
]);

export enum TradingOrderCategory {
  Internal = 'INTERNAL',
  Internalization = 'INTERNALIZATION',
  Iso = 'ISO',
  Mmpo = 'MMPO',
  Montage = 'MONTAGE',
  Standard = 'STANDARD'
}

const zTradingOrderCategory: z.ZodType<TradingOrderCategory> = z.enum([
  TradingOrderCategory.Internal,
  TradingOrderCategory.Internalization,
  TradingOrderCategory.Iso,
  TradingOrderCategory.Mmpo,
  TradingOrderCategory.Montage,
  TradingOrderCategory.Standard
]);

const advancedSelectValue = z.object({
  id: z.string(),
  value: z.unknown().optional(),
  label: z.string().nullable().optional()
});

export default createVersionedSchema('ROUTE_ORDER_FORM', {
  version: 0,
  schema: z.object({
    hiddenMode: z
      .union([
        z.object({
          type: z.literal('route'),
          investorOrderId: z.string(),
          investorOrderType: zOrderType.nullable().optional(),
          investorOrderLimitPrice: z.number().nullable().optional(),
          investorOrderQuantity: z.number().nullable().optional()
        }),
        z.object({
          type: z.literal('modify'),
          tradingOrderId: z.string()
        }),
        z.object({
          type: z.literal('create')
        })
      ])
      .optional(),
    hiddenStrategyOptions: z.array(z.string()).optional(),
    instrument: advancedSelectValue.optional(),
    venue: advancedSelectValue.optional(),
    trader: advancedSelectValue.optional(),
    sideType: zOrderSideType.optional(),
    display: z.string().optional(),
    timeInForce: z
      .object({
        id: zTimeInForce,
        subValue: z.string().optional()
      })
      .optional(),
    quantity: z.union([z.string(), z.number()]).optional(),
    locate: z.string().optional(),
    strategy: z
      .object({
        id: z.string(),
        value: z.object({
          strategyParams: z.array(z.any()),
          strategyControls: z.array(z.any()),
          strategyName: z.string(),
          venueId: z.string(),
          orderFormValues: z.any().optional(),
          isLayoutsPopulated: z.boolean().optional()
        }),
        label: z.string().nullable().optional()
      })
      .optional(),
    limitPrice: z.union([z.string(), z.number()]).optional(),
    customerNotes: z.string().optional(),
    venueNotes: z.string().optional(),
    tradeCurrency: advancedSelectValue.optional(),
    repCode: advancedSelectValue.optional(),
    settlementType: zOrderSettlementType.optional(),
    settlementDate: z.string().nullable().optional(),
    orderTags: z.array(advancedSelectValue).optional(),
    matchedInvestorOrderIds: z.array(z.string()).optional(),
    firmAccount: advancedSelectValue.optional(),
    tradingOrderCategory: zTradingOrderCategory.nullable().optional()
  })
});
