import { useRef, useCallback, type FocusEventHandler, useEffect, useMemo } from 'react';
import { type Field, type FieldProps } from './types';
import { useFieldApi as useDDFFieldApi, type UseFieldApiProps } from '@data-driven-forms/react-form-renderer';
import type { Optional } from '@oms/shared/util-types';

export const useFieldApi = <TFieldProps = Field, TFieldValue = any>(
  props: FieldProps<TFieldProps, TFieldValue>
) => {
  const { fieldDef, ...rest } = useDDFFieldApi<TFieldValue>(props);
  return useMemo(
    () =>
      ({
        fieldDef: fieldDef as Optional<TFieldProps>,
        ...(typeof fieldDef === 'object' && fieldDef !== null ? fieldDef : {}),
        ...rest
      } as UseFieldApiProps<TFieldValue, HTMLElement> & TFieldProps),
    [fieldDef, rest]
  );
};

/**
 * Hook to manage focus states:
 * - Select all on focus
 * - Auto focus on mount
 */
export const useFieldFocus = <T extends HTMLElement>(props: any) => {
  const ref = useRef<T>(null);

  const {
    selectAllOnFocus = false,
    autoFocus = false,
    input: { onFocus: _onFocus }
  } = useDDFFieldApi(props);

  // Handle auto focus
  useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
  }, [autoFocus]);

  // Handle select all on focus
  const onFocus: FocusEventHandler<T> = useCallback(
    (event) => {
      if (_onFocus) {
        _onFocus(event);
      }

      if (selectAllOnFocus && ref?.current instanceof HTMLInputElement) {
        ref?.current?.select();
      }
    },
    [selectAllOnFocus, _onFocus]
  );

  return [ref, onFocus] as const;
};
