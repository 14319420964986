import { Plugin, COMMON_ACTOR_TYPE, isDefined } from '@valstro/workspace';
import type {
  CreateActorOptions,
  ActorSnapshotDefinition,
  AnyRecord,
  ApplyActorRootSnapshotDefinition
} from '@valstro/workspace';
import type { AppWindowActorSchema, AppWorkspace } from '@app/app-config/workspace.config';
import { omit } from 'lodash';
import type { AppWindowContext } from '@app/app-config/workspace.config';

type RelativeWindowContext = AppWindowContext & {
  relativeX?: number;
  relativeY?: number;
  relativeWidth?: number;
  relativeHeight?: number;
  relativeMaxWidth?: number;
  relativeMaxHeight?: number;
  relativeMinWidth?: number;
  relativeMinHeight?: number;
};

/**
 * Cross-Platform Snapshots Plugin
 * - Converts all window positions & sizing to relative values on applySnapshot
 * - Restores window positions & sizing to absolute values on takeSnapshot
 * - Strips "name" from window snapshots so when switching platforms we don't use the wrong actors.
 */
export const crossPlatformSnapshotsPlugin = Plugin.create<AppWorkspace>({
  name: 'cross-platform-snapshots-plugin',
  pluginFn: ({ workspace }) => {
    /**
     * Convert all window positions & sizing to relative values on applySnapshot
     * And strip name from snapshot so when switching platforms we don't use the wrong actors.
     */
    workspace
      .addActorHook<AppWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW)
      .after('takeSnapshot', async (snapshot) => {
        const platformInfo = await workspace.getPlatformAPI().getPlatformInfo?.();

        const monitors = platformInfo?.monitors || [];

        snapshot = transformSnapshotDefinition<AppWindowContext, RelativeWindowContext>(
          snapshot,
          (child) => child.type === COMMON_ACTOR_TYPE.WINDOW,
          (snapshot) => {
            // Strip name from snapshot so when switching platforms we don't use the wrong actors.
            // E.g. if we switch from browser to Tauri, we don't want to use the browser tab / modal actors
            snapshot = stripWindowActorName(snapshot);

            const newSnapshot: CreateActorOptions<RelativeWindowContext> = {
              ...snapshot
            };

            const context = snapshot.context || {};
            const newContext: Partial<RelativeWindowContext> = {
              ...context
            };

            const monitor = monitors[context.currentMonitorIndex || 0];

            if (!monitor) {
              return newSnapshot;
            }

            if (isDefined(context.x) && isDefined(context.y)) {
              newContext.relativeX = context.x / monitor.width;
              newContext.relativeY = context.y / monitor.height;
            }

            if (isDefined(context.width) && isDefined(context.height)) {
              newContext.relativeWidth = context.width / monitor.width;
              newContext.relativeHeight = context.height / monitor.height;
            }

            if (isDefined(context.minWidth) && isDefined(context.minHeight)) {
              newContext.relativeMinWidth = context.minWidth / monitor.width;
              newContext.relativeMinHeight = context.minHeight / monitor.height;
            }

            if (isDefined(context.maxWidth) && isDefined(context.maxHeight)) {
              newContext.relativeMinWidth = context.maxWidth / monitor.width;
              newContext.relativeMinHeight = context.maxHeight / monitor.height;
            }

            return {
              ...newSnapshot,
              context: newContext
            };
          }
        );

        return snapshot;
      });

    /**
     * Convert all window positions & sizing from relative values on to absolute ones on applySnapshot
     */
    workspace
      .addActorHook<AppWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW)
      .before('applySnapshot', async (snapshot) => {
        const platformInfo = await workspace.getPlatformAPI().getPlatformInfo?.();

        const monitors = platformInfo?.monitors || [];

        const newSnapshot = transformRootSnapshotDefinitionContext<RelativeWindowContext, AppWindowContext>(
          snapshot,
          (child) => 'type' in child && child.type === COMMON_ACTOR_TYPE.WINDOW,
          ({
            relativeX,
            relativeY,
            relativeWidth,
            relativeHeight,
            relativeMaxWidth,
            relativeMaxHeight,
            relativeMinWidth,
            relativeMinHeight,
            ...rest
          }) => {
            const newContext: AppWindowContext = {
              ...rest
            };

            const monitor = monitors[rest.currentMonitorIndex || 0];

            if (!monitor) {
              return rest;
            }

            if (isDefined(relativeX) && isDefined(relativeY)) {
              newContext.x = relativeX * monitor.width;
              newContext.y = relativeY * monitor.height;
            }

            if (isDefined(relativeWidth) && isDefined(relativeHeight)) {
              newContext.width = relativeWidth * monitor.width;
              newContext.height = relativeHeight * monitor.height;
            }

            if (isDefined(relativeMinWidth) && isDefined(relativeMinHeight)) {
              newContext.minWidth = relativeMinWidth * monitor.width;
              newContext.minHeight = relativeMinHeight * monitor.height;
            }

            if (isDefined(relativeMaxWidth) && isDefined(relativeMaxHeight)) {
              newContext.maxWidth = relativeMaxWidth * monitor.width;
              newContext.maxHeight = relativeMaxHeight * monitor.height;
            }

            return newContext;
          }
        );

        return newSnapshot;
      });

    return function unsubscribe() {
      // Noop
    };
  }
});

function stripWindowActorName(
  snapshot: CreateActorOptions<AppWindowContext>
): CreateActorOptions<AppWindowContext> {
  return omit(snapshot, 'name');
}

function transformSnapshotDefinition<TContextFrom extends AnyRecord, TContextTo extends AnyRecord>(
  snapshot: ActorSnapshotDefinition,
  predidate: (child: CreateActorOptions) => boolean,
  transform: (snapshot: CreateActorOptions<TContextFrom>) => CreateActorOptions<TContextTo>
): ActorSnapshotDefinition {
  if (predidate(snapshot)) {
    snapshot = transform(snapshot as CreateActorOptions<TContextFrom>) as ActorSnapshotDefinition;
  }

  return {
    ...snapshot,
    children:
      snapshot?.children?.map((child) => transformSnapshotDefinition(child, predidate, transform)) || []
  };
}

function transformRootSnapshotDefinitionContext<TContextFrom extends AnyRecord, TContextTo extends AnyRecord>(
  snapshot: ApplyActorRootSnapshotDefinition,
  predidate: (child: CreateActorOptions | ApplyActorRootSnapshotDefinition) => boolean,
  transform: (context: TContextFrom) => TContextTo
): ApplyActorRootSnapshotDefinition {
  if (predidate(snapshot as CreateActorOptions)) {
    snapshot.context = transform(snapshot.context as TContextFrom);
  }

  return {
    ...snapshot,
    children:
      snapshot?.children?.map(
        (child) => transformRootSnapshotDefinitionContext(child, predidate, transform) as CreateActorOptions
      ) || []
  };
}
