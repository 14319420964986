// TODO: In the future, this should be a generated file.

import { gql } from '@apollo/client';
import type { TableServerJoinedTradingOrder } from '@oms/generated/frontend';

export type TradingOrderRow = TableServerJoinedTradingOrder;

export type TradingOrderMonitorKeys = keyof Omit<
  TableServerJoinedTradingOrder,
  | 'enteredBy'
  | 'orderTagNames'
  | 'representativeCodeId'
  | 'userIDs'
  | 'transmittedTimestamp'
  | 'tradingAccount'
  | 'isBoundOrder'
  | 'visibleSendingDesk'
  | 'visibleUnderlyingAccount'
  | 'displayQuantity'
  | 'enteredByEmail'
  | 'fixMessage'
  | 'minQuantity'
  | 'strategy'
  | 'tifDuration'
  | 'tradingAccountType'
  | 'venueNotes'
  | 'investorOrderIds'
>;

export const TsTradingOrdersWithFilterDocument = gql`
  subscription OnTsTradingOrdersWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsTradingOrdersWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsTradingOrderQueryResult {
        rows {
          averagePrice
          createdTimestamp
          enteredBy
          enteredByName
          executedQuantity
          id
          instrument
          instrumentCusip
          instrumentDisplayCode
          instrumentExchCode
          instrumentLongName
          leavesQuantity
          locate
          orderType
          orderVisibilityReason
          settleCurrency
          settleDate
          settleType
          side
          status
          timeInForce
          totalQuantity
          tradingAccount
          tradingAccountName
          updatedTimestamp
          venue
          representativeCode
          tradeCurrency
          customerNotes
          limitPrice
          orderTagIds
          orderTagNames
          capacity
          targetExchangeId
          category
          expiryDateTime
          ownerName
          ownerID
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsTradingOrderPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }
`;

export type TsTradingOrdersWithFilterSubscription = {
  tsTradingOrdersWithFilter: {
    rows?: TradingOrderRow[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};
