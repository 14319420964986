import { sharedDefaultCol } from '@app/common/grids/columns/generic-cols';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedDateTimeCol, sharedTextCol } from '@app/common/grids/columns/generic-cols';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';

const defaultAuditTrailCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDefaultCol<OrderAuditTrail>(c).minWidth(180);

const eventTimestampCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(200);

export const auditTrailColumnLibrary: ColumnLibrary<OrderAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [eventTimestampCol, eventCol]
};
