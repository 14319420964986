import React, {
  useState,
  useCallback,
  KeyboardEvent,
  ChangeEvent,
  CSSProperties,
  RefObject,
  forwardRef,
  useEffect,
  FocusEvent
} from 'react';
import { NumberFormatValues } from 'react-number-format';
import { NumberFormat, OnNumberFormatValueChange } from '../number-format/number-format.component';
import { Input } from '../input/input';
import { StackProps } from '../../layout/stack/stack';

interface IShortcut {
  key: string;
  value: string;
}

interface IPriceInputProps {
  onValueChange: (value: string | number) => void;
  onBlur: (event?: FocusEvent<HTMLElement, Element> | undefined) => void;
  onFocus: (event: FocusEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  sx: StackProps['sx'];
  shortcuts?: IShortcut[];
  style?: CSSProperties;
  ref?: RefObject<HTMLInputElement>;
  placeholder?: string;
}

export const PriceInput = forwardRef<HTMLInputElement, IPriceInputProps>(
  ({ onValueChange, onBlur, onFocus, value: _value, style, shortcuts, sx, name }, ref) => {
    const isControlled = onValueChange !== undefined;

    const [inputState, setInputState] = useState<{ mode: 'text' | 'number'; inputValue: string | undefined }>(
      {
        mode: 'text',
        inputValue: _value
      }
    );

    useEffect(() => {
      if (ref && typeof ref !== 'function' && ref.current) {
        ref.current.focus();
      }
    }, [inputState.mode]);

    useEffect(() => {
      if (isControlled) {
        const valueInShortcuts = shortcuts.find((s) => s.value === _value);

        if (valueInShortcuts) {
          setInputState({ mode: 'text', inputValue: _value });
        } else {
          setInputState({ mode: 'number', inputValue: _value });
        }
      }
    }, [_value, isControlled, shortcuts]);

    const handleTextInputKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const key = e.key.toLowerCase();

        const keyFoundInShortcuts = shortcuts.find((s) => s.key === key);

        if (isNumber(key)) {
          e.preventDefault();
          e.stopPropagation();
          setInputState({ mode: 'number', inputValue: key });
          onValueChange(key);
        } else if (keyFoundInShortcuts) {
          e.preventDefault();
          e.stopPropagation();

          if (onValueChange) {
            onValueChange(keyFoundInShortcuts.value);
          }
          setInputState({ mode: 'text', inputValue: keyFoundInShortcuts.value });
        }
      },
      [onValueChange, shortcuts]
    );

    const handleTextInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const valueInShortcuts = shortcuts.find((s) => s.value === value);
      if (valueInShortcuts) {
        setInputState((prev) => ({ ...prev, inputValue: valueInShortcuts.value }));
      }
    }, []);

    const handleNumberInputKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const key = e.key.toLowerCase();

        const keyFoundInShortcuts = shortcuts.find((s) => s.key === key);

        if (isLetter(key)) {
          e.preventDefault();
          e.stopPropagation();
          if (keyFoundInShortcuts) {
            e.preventDefault();
            e.stopPropagation();

            if (onValueChange) {
              onValueChange(keyFoundInShortcuts.value);
            }
            setInputState({ mode: 'text', inputValue: keyFoundInShortcuts.value });
          }
        }
      },
      [onValueChange, shortcuts]
    );

    const handleNumberInputChange = useCallback((value: NumberFormatValues) => {
      setInputState((prev) => ({ ...prev, inputValue: value.formattedValue }));
    }, []);

    const onValueChangeHandler: OnNumberFormatValueChange = useCallback(
      (values) => {
        if (onValueChange) {
          onValueChange(values.formattedValue);
        } else {
          handleNumberInputChange(values);
        }
      },
      [onValueChange, handleNumberInputChange]
    );

    return (
      <div>
        {inputState.mode === 'text' ? (
          <Input
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref}
            name={name}
            type="text"
            value={inputState.inputValue}
            style={style}
            onKeyDown={handleTextInputKeyDown}
            onChange={handleTextInputChange}
            sx={{
              textAlign: 'right',
              ...sx
            }}
          />
        ) : (
          <NumberFormat
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref}
            name={name}
            value={inputState.inputValue}
            style={style}
            onKeyDown={handleNumberInputKeyDown}
            onValueChange={onValueChangeHandler}
            thousandSeparator=","
            valueIsNumericString={true}
          />
        )}
      </div>
    );
  }
);

function isLetter(v: string) {
  return /^[a-z]$/i.test(v.toLowerCase());
}

function isNumber(v: string) {
  return /^[0-9.]$/.test(v);
}
