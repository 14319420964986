import { TEMPORARY_SNAPSHOT_ID } from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import type {
  SnapshotDocActors,
  SnapshotComputedDocType,
  SnapshotType
} from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { AppDatabase } from '@app/data-access/offline/app-database';
import { inject, singleton, delay } from 'tsyringe';
import { UUID } from '@oms/shared/util';
import { Actor, PROCESS_ID } from '@valstro/workspace';
import type { ActorSnapshotDefinition } from '@valstro/workspace';
import { map } from 'rxjs';
import type { Observable } from 'rxjs';
import { getCompositeId } from '@app/data-access/offline/collections/grids.collection';
import { ActionConfigService } from '@app/actions/services/action.config.service';
import type { AppSnapshotDefinition } from '@app/app-config/workspace.config';
import { Optional } from '@oms/shared/util-types';
import { LocalStorageService } from '../storage/local-storage.service';

const ACTIVE_LAYOUT_ID_KEY = 'valstro_active_layout_id' as const;

@singleton()
export class SnapshotsService {
  private _cachedLeaderActor: Actor | null = null;

  constructor(
    @inject(delay(() => AppDatabase)) private db: AppDatabase,
    @inject(LocalStorageService) private localStorage: LocalStorageService
  ) {}

  public getAll() {
    return this.db.memory.snapshots.find().exec();
  }

  public all$(type: SnapshotType): Observable<SnapshotComputedDocType[]> {
    const query = this.db.memory.snapshots.find({
      selector: { type },
      sort: [{ createdAt: 'desc' }]
    });

    return query.$.pipe(
      map((docs) => {
        return docs.map((doc) => {
          const snapshot: SnapshotComputedDocType = {
            ...doc.toMutableJSON(),
            isActive: doc.id === this.getActiveLayoutId()
          };
          return snapshot;
        });
      })
    );
  }

  public async save(type: SnapshotType, name: string) {
    const createdAt = new Date().toISOString();
    const { snapshot, actors } = await this.takeSnapshot();
    const id = UUID();
    await this.db.memory.snapshots.insert({
      id,
      type,
      name,
      createdAt,
      lastUpdatedAt: createdAt,
      snapshot,
      actors
    });
    this.setActiveLayoutId(id);
    await this.postSave(id);
  }

  public async saveOver(id: string) {
    const doc = await this.db.memory.snapshots.findOne(id).exec();
    if (!doc) {
      throw new Error(`Snapshot with id ${id} not found`);
    }
    const lastLoadedAt = new Date().toISOString();
    const lastUpdatedAt = lastLoadedAt;
    const { snapshot, actors } = await this.takeSnapshot();
    await doc.incrementalUpdate({
      $set: {
        snapshot,
        actors,
        lastLoadedAt,
        lastUpdatedAt
      }
    });
    this.setActiveLayoutId(id);
    await this.postSave(id);
  }

  public async load({ id, snapshot }: SnapshotComputedDocType) {
    const leaderActor = await this.getLeaderActor();
    this.setActiveLayoutId(id);
    await this.prepareLoad(id);
    await leaderActor.applySnapshot(snapshot);
  }

  public async getCurrent(): Promise<SnapshotComputedDocType | null> {
    const activeLayoutId = this.getActiveLayoutId();
    if (!activeLayoutId) return null;
    const snapshot = await this.db.memory.snapshots
      .findOne({
        selector: { id: activeLayoutId }
      })
      .exec();
    if (!snapshot) return null;
    return {
      ...snapshot.toMutableJSON(),
      isActive: true
    };
  }

  public async reloadCurrent() {
    const current = await this.getCurrent();
    if (!current) {
      return;
    }
    await this.load(current);
  }

  public async closeWindowsAll() {
    const { snapshot } = await this.takeSnapshot();
    const launcherOnlySnapshot = {
      ...snapshot,
      children: snapshot.children.filter((child) => child.type === 'widget')
    };
    const leaderActor = await this.getLeaderActor();
    await leaderActor.applySnapshot(launcherOnlySnapshot);
  }

  public active$(): Observable<SnapshotComputedDocType | null> {
    return this.db.memory.snapshots
      .findOne({
        selector: { id: this.getActiveLayoutId() }
      })
      .$.pipe(
        map((snapshot) => {
          if (!snapshot) return null;
          return {
            ...snapshot.toMutableJSON(),
            isActive: true
          };
        })
      );
  }

  public async delete(snapshotIds: string[]) {
    const activeLayoutId = this.getActiveLayoutId();
    if (activeLayoutId && snapshotIds.includes(activeLayoutId)) this.removeActiveLayoutId();
    await this.db.memory.snapshots.bulkRemove(snapshotIds);
  }

  public findByMatchingActors(id: string, type?: string) {
    return this.db.memory.snapshots
      .find({
        selector: {
          $and: [
            {
              actors: {
                $elemMatch: type ? { id, type } : { id }
              }
            }
          ]
        }
      })
      .exec();
  }

  private getActiveLayoutId(): Optional<string> {
    return this.localStorage.getString(ACTIVE_LAYOUT_ID_KEY);
  }

  private setActiveLayoutId(id: string) {
    this.localStorage.setString(ACTIVE_LAYOUT_ID_KEY, id);
  }

  private removeActiveLayoutId() {
    this.localStorage.removeItem(ACTIVE_LAYOUT_ID_KEY);
  }

  private async takeSnapshot() {
    const leaderActor = await this.getLeaderActor();
    const snapshot = (await leaderActor.takeSnapshot()) as AppSnapshotDefinition;
    const actors = this.extractFlatActorList(snapshot);
    return {
      snapshot,
      actors
    };
  }

  private extractFlatActorList(def: ActorSnapshotDefinition, parentId?: string): Array<SnapshotDocActors> {
    const actors: SnapshotDocActors[] = [];
    actors.push({ id: def.id, type: def.type, name: def.name, parentId });
    for (const child of def.children) {
      actors.push(...this.extractFlatActorList(child, def.id));
    }
    return actors;
  }

  private async getLeaderActor() {
    if (!this._cachedLeaderActor) {
      this._cachedLeaderActor = await Actor.get(PROCESS_ID.LEADER);
    }
    return this._cachedLeaderActor;
  }

  private async prepareLoad(snapshotId: string) {
    await this.prepareGrids(snapshotId);
    await this.prepareActions(snapshotId);
  }

  private async postSave(snapshotId: string) {
    await this.pruneActions(snapshotId);
    await this.pruneGrids(snapshotId);
  }

  private async pruneActions(snapshotId: string) {
    // delete all grids with the matching snapshotId just taken
    const actionsWithMatchingSnapshotId = await this.db.memory.actions
      .find({
        selector: {
          gridStateId: {
            $regex: snapshotId
          }
        }
      })
      .exec();
    const actionIds = actionsWithMatchingSnapshotId.map((action) => action.id);
    await this.db.memory.actions.bulkRemove(actionIds);

    const allTemporaryActions = await this.db.memory.actions
      .find({ selector: { gridStateId: { $regex: TEMPORARY_SNAPSHOT_ID } } })
      .exec();

    // and duplicate them with the new snapshotId
    const newSnapshotActions = allTemporaryActions.map((action) => ({
      ...action.toMutableJSON(),
      gridStateId: getCompositeId(action.gridStateId.split('_')[1], snapshotId)
    }));

    await this.db.memory.actions.bulkUpsert(
      newSnapshotActions.map((a) => ({ ...a, id: ActionConfigService.idOf(a) }))
    );
  }

  private async pruneGrids(snapshotId: string) {
    // delete all grids with the matching snapshotId just taken
    const gridsWithMatchingSnapshotId = await this.db.offline.grids.find({ selector: { snapshotId } }).exec();
    const gridIds = gridsWithMatchingSnapshotId.map((grid) => grid.id);
    await this.db.offline.grids.bulkRemove(gridIds);

    // get all temporary grids
    const allTemporaryGrids = await this.db.offline.grids
      .find({ selector: { snapshotId: TEMPORARY_SNAPSHOT_ID } })
      .exec();

    // and duplicate them with the new snapshotId
    const newSnapshotGrids = allTemporaryGrids.map((grid) => ({
      ...grid.toMutableJSON(),
      id: getCompositeId(grid.gridId, snapshotId),
      snapshotId
    }));

    await this.db.offline.grids.bulkUpsert(newSnapshotGrids);
  }

  private async prepareGrids(snapshotId: string) {
    // get & remove temporary grids
    const allTemporaryGrids = await this.db.offline.grids
      .find({ selector: { snapshotId: TEMPORARY_SNAPSHOT_ID } })
      .exec();
    const allTemporaryGridIds = allTemporaryGrids.map((grid) => grid.id);
    await this.db.offline.grids.bulkRemove(allTemporaryGridIds);
    await this.db.offline.grids.cleanup();

    // get all snapshot grids
    const gridsWithMatchingSnapshotId = await this.db.offline.grids.find({ selector: { snapshotId } }).exec();

    // and duplicate them to temporary grids
    const newTempGrids = gridsWithMatchingSnapshotId.map((grid) => ({
      ...grid.toMutableJSON(),
      id: getCompositeId(grid.gridId, TEMPORARY_SNAPSHOT_ID),
      snapshotId: TEMPORARY_SNAPSHOT_ID
    }));

    await this.db.offline.grids.bulkUpsert(newTempGrids);
  }

  private async prepareActions(snapshotId: string) {
    const allTemporaryActions = await this.db.memory.actions
      .find({ selector: { gridStateId: { $regex: TEMPORARY_SNAPSHOT_ID } } })
      .exec();
    const allTemporaryActionIds = allTemporaryActions.map((action) => action.id);
    await this.db.memory.actions.bulkRemove(allTemporaryActionIds);
    await this.db.memory.actions.cleanup();

    const actionsWithMatchingSnapshotIds = await this.db.memory.actions
      .find({ selector: { gridStateId: { $regex: snapshotId } } })
      .exec();

    const newTempActions = actionsWithMatchingSnapshotIds
      .map((action) => ({
        ...action.toMutableJSON(),
        gridStateId: getCompositeId(action.gridStateId.split('_')[1], TEMPORARY_SNAPSHOT_ID)
      }))
      .map((a) => ({ ...a, id: ActionConfigService.idOf(a) }));

    await this.db.memory.actions.bulkUpsert(newTempActions);
  }
}
