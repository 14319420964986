import { Actor, COMMON_ACTOR_TYPE, widgetActorDef } from '@valstro/workspace';
import type { SpawnedActorResult, CreateActorChildOptions, AnyRecord } from '@valstro/workspace';
import { FLEX_LAYOUT_ACTOR_TYPE } from '@valstro/workspace-react';
import { COMMON_OBJECT_CATEGORY } from '@app/app-config/registry.config';
import type { OpenableRegistryDefinition } from '@app/app-config/registry.config';
import type {
  AppWidgetContext,
  AppWindowContext,
  AppFlexLayoutContext,
  AppWindowActorSchema
} from '@app/app-config/workspace.config';

/**
 * Opens a window actor with a widget component, form, layout or dialog
 * Note: This is an internal function used by codegen and should not be used directly
 *
 * @param definition - The definition of the widget, form, layout or dialog
 * @param parentActorOrId - The parent actor or id to spawn the window actor from
 * @returns The spawned window actor
 */
export async function internalOpenInWindowActor<TProps>(
  definition: OpenableRegistryDefinition<TProps>,
  parentActorOrId: Actor | string,
  lazy?: boolean
): Promise<SpawnedActorResult<AppWindowActorSchema>> {
  const parentActor =
    parentActorOrId instanceof Actor
      ? parentActorOrId
      : lazy
        ? Actor.getSyncLazy(parentActorOrId)
        : await Actor.get(parentActorOrId);
  const parentId = parentActor.id;
  const type = definition.type;

  // default values
  let id: string | undefined = undefined;
  let windowOptions: Partial<AppWindowContext> = {
    title: definition.title,
    ...definition.windowOptions,
    meta: {
      widgetType: definition.key,
      requiredEntitlements: definition.hasEntitlement,
      ...definition.windowOptions?.meta
    }
  };
  const defaultComponentProps = 'componentProps' in definition ? definition.componentProps : ({} as TProps);
  let widgetComponentProps: AppWidgetContext['componentProps'] = {
    ...defaultComponentProps
  };
  let children: CreateActorChildOptions[] = [];

  // determine the id based on the type
  switch (type) {
    case 'dialog':
      id = getDialogId(definition.key, parentId);
      break;
    default:
      id = definition.windowId ? definition.windowId : definition.isUnique ? definition.key : undefined;
      break;
  }

  // Add widgetType to componentProps
  widgetComponentProps = {
    ...widgetComponentProps,
    widgetType: definition.key
  };

  // determine the window options and children based on the type
  switch (type) {
    case 'dialog':
      windowOptions = {
        width: 500,
        height: 300,
        isMaximizable: false,
        ...windowOptions,
        isMinimizable: false,
        isTitleEditable: false,
        meta: {
          windowType: 'dialog',
          widgetCategory: COMMON_OBJECT_CATEGORY.SYSTEM,
          widgetType: definition.key,
          ...windowOptions.meta
        }
      };
      children = [widgetActorDef(definition.componentKey, widgetComponentProps)];
      break;
    case 'form':
    case 'component':
      windowOptions = {
        ...windowOptions,
        isBrowserTab: !!definition.openTabInBrowser,
        meta: {
          widgetCategory: definition.objectCategory,
          widgetType: definition.key,
          ...windowOptions.meta
        }
      };
      children = [
        widgetActorDef(
          type === 'component' ? definition.componentKey : 'SYSTEM_REMOTE_FORM',
          widgetComponentProps
        )
      ];
      break;
    case 'layout': {
      windowOptions = {
        ...windowOptions,
        meta: {
          widgetCategory: definition.objectCategory,
          widgetType: definition.key,
          ...windowOptions.meta
        }
      };
      const flexLayoutActorContext: AppFlexLayoutContext = {
        layout: definition.jsonModel.layout,
        componentProps: widgetComponentProps as AnyRecord,
        borders: definition.jsonModel.borders,
        global: definition.jsonModel.global
      };
      children = [
        {
          id: definition.flexLayoutActorId,
          type: FLEX_LAYOUT_ACTOR_TYPE,
          context: flexLayoutActorContext
        }
      ];
      break;
    }
  }

  return parentActor.spawnChild<AppWindowActorSchema>({
    type: COMMON_ACTOR_TYPE.WINDOW,
    id,
    context: windowOptions,
    children
  });
}

export function getDialogId(key: string, parentActorOrId: Actor | string) {
  const parentId = parentActorOrId instanceof Actor ? parentActorOrId.id : parentActorOrId;
  return `dialog-${parentId}-${key}`;
}

/**
 * Base class for openable windows.
 */
export class BaseWindowOpenable<_T = any> {
  constructor(private _actor: Actor<AppWindowActorSchema>) {}

  /**
   * Get the actor to get state & perform operations on the window itself.
   */
  public get window() {
    return this._actor;
  }

  /**
   * Get the ID which is the window ID.
   */
  public get id() {
    return this._actor.id;
  }

  /**
   * Get the parent ID which is the window parent ID.
   */
  public get parentId() {
    return this._actor.parentId;
  }

  /**
   * Close the window.
   *
   * @returns - Promise that resolves when the form is closed.
   */
  public close() {
    return this._actor.operations.close();
  }

  /**
   * Dispose of the window.
   * This will close the window and remove it from the workspace.
   */
  public dispose() {
    this.close().catch(console.error);
  }
}
