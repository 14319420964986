export type InputMaybe<T> = T | null | undefined;

enum OrderType {
  Limit = 'LIMIT',
  Market = 'MARKET'
}

enum OrderSideType {
  Btc = 'BTC',
  Buy = 'BUY',
  Exempt = 'EXEMPT',
  Sell = 'SELL',
  Short = 'SHORT'
}

enum TimeInForce {
  Close = 'CLOSE',
  Open = 'OPEN',
  Day = 'DAY',
  Duration = 'DURATION', // This value is not supported in FIX
  Gtc = 'GTC',
  Gtd = 'GTD',
  Ioc = 'IOC',
  TifNotSupported = 'TIF_NOT_SUPPORTED'
}

type MappableOrder = {
  limitPrice?: InputMaybe<number>;
  orderType?: InputMaybe<OrderType>;
  quantity?: InputMaybe<number>;
  sideType?: InputMaybe<OrderSideType>;
  timeInForce?: InputMaybe<TimeInForce>;
};

const fixFields = {
  ORDER_QUANITY: 'FIX_OrderQty',
  SIDE: 'FIX_Side',
  ORDER_TYPE: 'FIX_OrderType',
  TIME_IN_FORCE: 'FIX_TimeInForce',
  PRICE: 'FIX_Price'
};

const FIXSideValueMapping = {
  BUY: 1,
  BUY_TO_COVER: 3,
  SELL: 2,
  SELL_SHORT: 5,
  SELL_SHORT_EXEMPT: 6
} as const;

const FIXOrderTypeValueMapping = {
  MARKET: 1,
  LIMIT: 2
} as const;

const FIXTimeInForceValueMapping = {
  CLOSE: 7,
  OPEN: 2,
  DAY: 0,
  GTC: 1,
  GTD: 6,
  IOC: 3
} as const;

const mapValue = (fixFieldName: string, value: string | number | Date) => {
  switch (fixFieldName) {
    case fixFields.SIDE:
      return FIXSideValueMapping[value as keyof typeof FIXSideValueMapping];
    case fixFields.ORDER_TYPE:
      return FIXOrderTypeValueMapping[value as keyof typeof FIXOrderTypeValueMapping];
    case fixFields.TIME_IN_FORCE:
      return FIXTimeInForceValueMapping[value as keyof typeof FIXTimeInForceValueMapping];
  }

  return value;
};

export const mapOrderFieldsToFixRepresentation = (input: MappableOrder) => {
  const mappedObj = {
    FIX_OrderQty: input.quantity,
    FIX_Side: input.sideType,
    FIX_OrderType: input.orderType,
    FIX_TimeInForce: input.timeInForce,
    FIX_Price: input.limitPrice
  };

  return Object.entries(mappedObj).reduce((result, [key, value]) => {
    if (value != undefined) {
      result[key] = mapValue(key, value);
    }
    return result;
  }, {} as Record<string, string | number | Date>);
};
