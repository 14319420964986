import { sharedDefaultCol, sharedQuantityCol } from '@app/common/grids/columns/generic-cols';
import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedDateTimeCol, sharedTextCol, sharedPriceCol } from '@app/common/grids/columns/generic-cols';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';

const defaultAuditTrailCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDefaultCol<OrderAuditTrail>(c).minWidth(100);

const eventTimestampCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(200);

const bidPriceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'bidPrice').header('Bid');

const askPriceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'askPrice').header('Ask');

const lastTradePriceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedPriceCol(c, 'lastTradePrice').header('Last Trd').shortHeader('Last');

const cumulativeVolumeCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedQuantityCol(c, 'cumulativeVolume').header('Cumulative Volume').shortHeader('Vol');

export const auditTrailColumnLibrary: ColumnLibrary<OrderAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [eventTimestampCol, eventCol, bidPriceCol, askPriceCol, lastTradePriceCol, cumulativeVolumeCol]
};
