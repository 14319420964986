import { singleton, inject } from 'tsyringe';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { UiStateDocumentType } from '../../offline/types';
import {
  PullUiStatesDocument,
  PullUiStatesQuery,
  PullUiStatesQueryVariables,
  PushUiStatesDocument,
  PushUiStatesMutation,
  PushUiStatesMutationVariables,
  UiStatesIndexableFieldsInput
} from '@oms/generated/frontend';

export type ReplicationPushChangeRow = {
  newDocumentState: UiStateDocumentType;
  assumedMasterState?: UiStateDocumentType;
};

@singleton()
export class UiStateService {
  constructor(@inject(ApolloClientRPC) private apolloClient: ApolloClientRPC) {}

  public pullUiState(currentDocument: PullUiStatesQueryVariables, collection: string) {
    return this.apolloClient.query<PullUiStatesQuery, PullUiStatesQueryVariables>({
      query: PullUiStatesDocument,
      fetchPolicy: 'network-only',
      variables: {
        id: currentDocument.id,
        collection,
        lastUpdatedAt: currentDocument.lastUpdatedAt,
        limit: 100
      }
    });
  }

  public pushUiState(
    changeRows: ReplicationPushChangeRow[],
    indexableFields: UiStatesIndexableFieldsInput[],
    collection: string
  ) {
    return this.apolloClient.mutate<PushUiStatesMutation, PushUiStatesMutationVariables>({
      mutation: PushUiStatesDocument,
      variables: {
        documents: changeRows.map((changeRow) => JSON.stringify(changeRow)),
        indexableFields,
        collection
      }
    });
  }
}
