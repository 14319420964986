import type { Observable } from 'rxjs';
import { GetTagsDocument } from '@oms/generated/frontend';
import type { GetTagsQuery, TagFragment, TagsEdgeFragment, TagTypeEnum } from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { toGqlDatasource } from '@oms/frontend-foundation';
import type { DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import { getOperationName } from '@apollo/client/utilities';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';

const ORDER_TAGS_QUERY_NAME = getOperationName(GetTagsDocument);

@singleton()
export class OrderTagsService implements ICrudService<TagFragment> {
  private _apolloClient: ApolloClientRPC;
  public static refetchQueryName = ORDER_TAGS_QUERY_NAME;
  public static refetchQueries = ORDER_TAGS_QUERY_NAME ? [ORDER_TAGS_QUERY_NAME] : [];

  constructor(@inject(ApolloClientRPC) apolloClient: ApolloClientRPC) {
    this._apolloClient = apolloClient;
  }

  public watchAll$(...tagTypes: TagTypeEnum[]): Observable<DataSourceCommon<TagFragment>> {
    return this._apolloClient
      .watchQuery<GetTagsQuery>({
        query: GetTagsDocument
      })
      .pipe(
        toGqlDatasource((d) => {
          const tags = (d.tags?.edges as TagsEdgeFragment[])?.map((e) => e.node);

          if (tagTypes.length) {
            return tags.filter((tag) => tag.tagTypes.some((t) => t && tagTypes.includes(t)));
          }

          return tags;
        })
      );
  }
}
