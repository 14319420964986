import type { Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { MarketData, MarketDataEvent } from './marketdata.common';
import type { IMarketDataService } from './marketdata.common';
import { MdaasMarketDataService } from './mdaas-marketdata.service';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { ProcessState } from '@app/data-access/memory/process-id.subject';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GetInstrumentDocument } from '@oms/generated/frontend';
import type { GetInstrumentQuery, GetInstrumentQueryVariables } from '@oms/generated/frontend';

@singleton()
export class MarketDataService implements IMarketDataService {
  protected _service: IMarketDataService;
  protected _workspace: AppWorkspace;
  protected _processService: ProcessState;
  protected _apolloClient: ApolloClientRPC;

  constructor(
    @inject(AppWorkspace) workspace: AppWorkspace,
    @inject(ProcessState) processService: ProcessState,
    @inject(ApolloClientRPC) apolloClient: ApolloClientRPC
  ) {
    this._workspace = workspace;
    this._processService = processService;
    this._service = new MdaasMarketDataService(this._workspace, this._processService);
    this._apolloClient = apolloClient;
  }

  public observe(...tickers: string[]): Observable<MarketDataEvent> {
    return this._service.observe(...tickers);
  }

  public hasAllTickers(...tickers: string[]): boolean {
    return this._service.hasAllTickers(...tickers);
  }

  public read(ticker: string): MarketData | undefined {
    return this._service.read(ticker);
  }

  public readAll(...tickers: string[]): (MarketData | undefined)[] {
    return this._service.readAll(...tickers);
  }

  public async getInstrumentById(id: string) {
    const response = await this._apolloClient.query<GetInstrumentQuery, GetInstrumentQueryVariables>({
      query: GetInstrumentDocument,
      variables: { id }
    });

    if (response.data && response.data.instrument) {
      return response.data.instrument;
    }

    if (response.errors) {
      console.error(response.errors);
    }

    throw new Error('Failed to fetch instrument details');
  }
}
