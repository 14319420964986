import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import {
  CloseButton,
  InputGroup,
  Tag,
  type InputGroupProps,
  useControllableState
} from '@oms/shared-frontend/ui-design-system';
import { isTauri, type CommonWindowActorSchema } from '@valstro/workspace';
import { useMergedRef, useRootActor, useWorkspace } from '@valstro/workspace-react';
import { POPOVER_EVENT } from '@valstro/workspace-plugin-popover';
import {
  PopoverTrigger,
  type PopoverTriggerProps,
  usePopoverTransport
} from '@valstro/workspace-react-plugin-popover';
import { type PopoverAutocompleteComponentProps } from './popover.autocomplete.component';
import { POPOVER } from '../popover.config';
import { type LabelFormattingFunction, formatLabel } from '../../utils/label-format-utils';
import {
  type ComboBoxItem,
  type ComboBoxItemUnion,
  type ComboBoxDefaultValue
} from '../../combo-box/combo-box.types';
import findComboBoxItem from '../../combo-box/util/find-combo-box-item';
import {
  AUTOCOMPLETE_POPOVER_ITEM_CLICK_EVENT,
  type AutocompetePopoverItemClickEvent,
  useAutocompleteKeyboardSubject
} from './popover.autocomplete.events';
import { appWindow } from '@tauri-apps/api/window';
import { useDeepCompareEffect } from 'react-use';

export type AutocompleteProps<T extends ComboBoxDefaultValue = ComboBoxDefaultValue> = Omit<
  PopoverTriggerProps,
  'ariaType' | 'componentId' | 'height' | 'width' | 'asChild' | 'value' | 'ariaLabel' | 'onChange'
> & {
  ariaLabel?: string;
  name: string;
  inputValue?: string;
  initialInputValue?: string;
  options: ComboBoxItemUnion<T>[];
  onInputValueChange?: (value: string) => void;
  isLoading?: boolean;
  isInputLoading?: boolean;
  isInvalidated?: boolean;
  errorMessage?: string;
  value?: ComboBoxItem<T>[];
  initialValue?: ComboBoxItem<T>[];
  onChange?: (value: ComboBoxItem<T>[]) => void;
  inputProps?: InputGroupProps;
  isTypeahead?: boolean;
  isMultiSelect?: boolean;
  filterStrategy?: PopoverAutocompleteComponentProps['filterStrategy'];
  autoSelectItemOnTab?: boolean;
  allowAnyValue?: boolean;
  formatValue?: LabelFormattingFunction;
  fallbackValue?: string;
  optionsOnFocus?: boolean;
  onItemClick?: (item: ComboBoxItem<T>) => void;
  /**
   * Defaults to true
   */
  autoSizeWidthToTrigger?: boolean;
  width?: number;
  minWidth?: number;
  minHeight?: number;
  open?: boolean;
};

const AutocompleteComponent = <T extends ComboBoxDefaultValue = ComboBoxDefaultValue>(
  {
    options,
    inputProps = {},
    isTypeahead = false,
    optionsOnFocus = false,
    value: _value,
    initialValue: _initialValue = [],
    onChange: _onChange,
    onInputValueChange: _onInputValueChange,
    isMultiSelect = false,
    filterStrategy,
    autoSelectItemOnTab = false,
    allowAnyValue = false,
    isLoading = false,
    isInputLoading = false,
    isInvalidated = false,
    errorMessage = '',
    formatValue,
    fallbackValue,
    onItemClick,
    ariaLabel,
    autoSizeWidthToTrigger = true,
    autoSizeWidth,
    inputValue: _inputValue,
    initialInputValue: _initialInputValue = '',
    open: _open,
    ...props
  }: AutocompleteProps<T>,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>
) => {
  const popoverEventsTransport = usePopoverTransport();
  const popoverEventsTransportRef = useRef(popoverEventsTransport); // Need to do this because popoverEventsTransport is not stable at the mo.
  const name = props.name;
  const isDisabledOrReadOnly = !!inputProps.isDisabled || !!inputProps.isReadOnly;
  const rootActor = useRootActor<CommonWindowActorSchema>();
  const workspace = useWorkspace();
  const popoverIdRef = useRef<string>(workspace.generateUUID());
  const [open, setOpen] = useState(false);
  const prevOpenRef = useRef<boolean>(false);
  const itemClickedRef = useRef<boolean>(false);
  const shouldRefocusRef = useRef<boolean>(false);
  const internalTriggerRef = useRef<HTMLElement>(null);
  const valueRef = useRef<ComboBoxItem[] | undefined>(_value);
  const prevValueRef = useRef<ComboBoxItem[] | undefined>(_value);
  const currentHighlightedItem = useRef<ComboBoxItem<any> | null | undefined>();
  const manuallyHighlightingItemsFlag = useRef<boolean>(false);
  const selectingOnTabRef = useRef<boolean>(false);
  const keyboard$ = useAutocompleteKeyboardSubject(popoverIdRef.current);

  useEffect(() => {
    setOpen(!!_open);
  }, [_open]);

  /**
   * Format Input Value based on value
   */
  const formatInputValue = useCallback(
    (value?: ComboBoxItem<any>): string => {
      const { label, sublabel } = value ?? {};

      const item = findComboBoxItem(options || [], { item: (value ?? {}) as ComboBoxItem });

      if (item) {
        return item.label;
      }

      const formatWithLabelOnly: LabelFormattingFunction = ({ code }) =>
        code ?? fallbackValue ?? sublabel ?? '';

      const formatter: LabelFormattingFunction = formatValue ?? formatWithLabelOnly;
      return formatLabel({ code: label, description: sublabel }, { formatter, fallback: fallbackValue });
    },
    [options, fallbackValue, formatValue]
  );

  /**
   * Controllable Selected Options
   */
  const [value, setValue] = useControllableState({
    value: _value,
    onChange: _onChange,
    defaultValue: _initialValue
  });

  /**
   * Controllable Input Value
   */
  const [inputValue, setInputValue] = useControllableState({
    value: _inputValue,
    onChange: _onInputValueChange,
    defaultValue: isMultiSelect
      ? _initialInputValue
      : _inputValue || value?.[0]
      ? formatInputValue(value[0])
      : _initialInputValue
  });

  /**
   * Value ref (for internal use inside useDeepCompareEffects with no deps)
   */
  valueRef.current = _value === undefined ? [] : _value;

  /**
   * On Input Value Change Handler
   */
  const onInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  /**
   * On Focus
   */
  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (inputProps.onFocus) {
        inputProps.onFocus(e);
      }
    },
    [inputProps]
  );

  /**
   * On Focus Click Trigger Handler (optionsOnFocus = true)
   */
  const onFocusClick = useCallback(() => {
    if (optionsOnFocus && !isDisabledOrReadOnly) {
      setOpen(true);
    }
  }, [optionsOnFocus, isDisabledOrReadOnly]);

  /**
   * Set the value AND input value based on the selected value & formatValue function
   */
  const valuesSetter = useCallback(
    (value: ComboBoxItem<any>[]) => {
      setValue(value);

      if (!isMultiSelect && value?.length) {
        setInputValue(formatInputValue(value[0]));
      }

      if (isMultiSelect) {
        setInputValue('');
      }
    },
    [formatInputValue, isMultiSelect, setInputValue, setValue]
  );

  /**
   * Keep the input value in sync with the selected value if it's a single select & being set from the outside
   */
  useDeepCompareEffect(() => {
    if (!isMultiSelect && value?.[0]?.label && value?.[0]?.label !== inputValue) {
      setInputValue(value[0].label);
    } else if (!isMultiSelect && !value?.length && !!prevValueRef?.current?.length) {
      setInputValue('');
    }
    prevValueRef.current = value;
  }, [isMultiSelect, value, inputValue, setInputValue]);

  /**
   * Typeahead Trigger Handler (optionsOnFocus = false)
   */
  useEffect(() => {
    const isInputFocused = document.activeElement === internalTriggerRef.current;
    if (isInputFocused && selectingOnTabRef.current === false) {
      setOpen(inputValue !== '' && !isDisabledOrReadOnly);
    }

    selectingOnTabRef.current = false;
  }, [optionsOnFocus, inputValue, isDisabledOrReadOnly]);

  /**
   * Listen to open change to reset currentHighlightedItem
   */
  useEffect(() => {
    if (open !== prevOpenRef.current) {
      currentHighlightedItem.current = undefined;
      prevOpenRef.current = open;
    }
  }, [open]);

  useDeepCompareEffect(() => {
    // Ensure the autocomplete window is focused after the value has changed
    if (isTauri()) {
      appWindow.setFocus().catch(console.error);
    }

    if (shouldRefocusRef.current) {
      internalTriggerRef.current?.focus();
      shouldRefocusRef.current = false;
    }

    itemClickedRef.current = false;

    if (!isMultiSelect && value?.length) {
      setOpen(false);
      setInputValue(formatInputValue(value[0]));
    }
  }, [value]);

  /**
   * Listen to onItemClick events from the popover component opened
   */
  useEffect(() => {
    const unlisten = popoverEventsTransportRef.current.listen(
      POPOVER_EVENT.POPOVER_CUSTOM_EVENT,
      ({ event, popoverId, windowId }) => {
        itemClickedRef.current = true;
        if (
          event.type === AUTOCOMPLETE_POPOVER_ITEM_CLICK_EVENT &&
          popoverId === popoverIdRef.current &&
          windowId === rootActor.id
        ) {
          if (onItemClick) {
            const e = event as AutocompetePopoverItemClickEvent<T>;
            const item = e.payload.item;
            onItemClick(item);
          }

          shouldRefocusRef.current = true;
        }
      }
    );

    return () => {
      unlisten();
    };
  }, [name, onItemClick, rootActor.id]);

  /**
   * Component Props
   */
  const outgoingComponentProps: PopoverAutocompleteComponentProps = useMemo(() => {
    const hasValue = !!value?.length;
    // If we have a value, we want to send the input value as empty string to the popover
    // So it doesn't filter the options and show us nothing in the list
    const outgoingInputValue = hasValue && !isMultiSelect ? '' : inputValue;
    return {
      inputValue: outgoingInputValue,
      options,
      selectedOptions: value,
      isMultiSelect,
      filterStrategy,
      isLoading: !!isLoading,
      allowAnyValue,
      isInvalidated,
      errorMessage
    };
  }, [
    isLoading,
    inputValue,
    options,
    value,
    isMultiSelect,
    filterStrategy,
    allowAnyValue,
    isInvalidated,
    errorMessage
  ]);

  /**
   * Track the selected option & highlight changes
   */
  useEffect(() => {
    const unlisten = popoverEventsTransportRef.current.listen(
      POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE,
      ({ popoverId, windowId, componentProps }) => {
        if (popoverId === popoverIdRef.current && windowId === rootActor.id) {
          const { selectedOptions, highlightedItem } =
            (componentProps as PopoverAutocompleteComponentProps) || {};

          if (highlightedItem !== undefined) {
            currentHighlightedItem.current = highlightedItem;
          }

          if (selectedOptions !== undefined && !isEqual(selectedOptions, valueRef.current)) {
            valuesSetter(selectedOptions);
            valueRef.current = selectedOptions;
          }
        }
      }
    );

    return () => {
      unlisten();
    };
  }, [rootActor, valuesSetter]);

  /**
   * Handle the click event on a single option that's selected
   */
  const onSingleTagReselect = useCallback(() => {
    if (isDisabledOrReadOnly) {
      return;
    }
    setOpen(true);
  }, [isDisabledOrReadOnly]);

  /**
   * Handle the removal of a selected item
   */
  const handleRemove = useCallback(
    (selection: ComboBoxItem | null) => {
      shouldRefocusRef.current = true;

      const nextSelectedOptions =
        selection === null ? [] : (value || []).filter((i) => i?.id !== selection.id);

      setValue(nextSelectedOptions);
      valueRef.current = nextSelectedOptions;
      setInputValue('');
      popoverEventsTransportRef.current
        .emit(POPOVER_EVENT.POPOVER_COMPONENT_PROPS_UPDATE, {
          popoverId: popoverIdRef.current,
          windowId: rootActor.id,
          method: 'merge',
          componentProps: {
            selectedOptions: nextSelectedOptions
          }
        })
        .catch(console.error);
    },
    [value, rootActor.id, setValue, setInputValue]
  );

  /**
   * Handle the keyboard events
   * - Sends the keyboard event to the subject
   * - Handles various key behaviors in relation to setting value & input value
   */
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const hasInputValue = !!target.value;
      const hasSelectedValue = !!value?.length;
      const isSingleSelect = !isMultiSelect;
      const isAllTextSelected = document?.getSelection()?.toString() === target.value;
      const isLetter = /^[a-z]$/i.test(e.key);
      const isNumber = /^[0-9]$/i.test(e.key);
      const isAlphaNumeric = isLetter || isNumber;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          manuallyHighlightingItemsFlag.current = true;
          keyboard$.next('ArrowDown');
          break;
        case 'ArrowUp':
          e.preventDefault();
          manuallyHighlightingItemsFlag.current = true;
          keyboard$.next('ArrowUp');
          break;
        case 'Enter':
          e.preventDefault();
          keyboard$.next('Enter');
          shouldRefocusRef.current = true;
          break;
        case 'Escape':
          keyboard$.next('Escape');
          break;
        case 'Backspace': {
          keyboard$.next('Backspace');
          const shouldClearSingleValue = isSingleSelect && hasSelectedValue && hasInputValue;
          const shouldClearMultiValue = isMultiSelect && hasSelectedValue && hasInputValue === false;

          if (shouldClearMultiValue) {
            const v: ComboBoxItem<any>[] = value || [];
            const newV = v.slice(0, -1) || [];
            valueRef.current = newV;
            setValue(newV);
            return;
          }

          if (shouldClearSingleValue) {
            setInputValue(isAllTextSelected ? '' : target.value);
            setValue([]);
          }

          break;
        }
        case 'Tab': {
          const inputVal = target.value;
          const uniqueCurrentVal = (value || []).filter((v) => v.id !== inputVal);
          const customItem: ComboBoxItem<any> = {
            type: 'item',
            label: inputVal,
            value: inputVal,
            id: inputVal
          };

          const shouldAutoSelectItemOnTab = autoSelectItemOnTab && currentHighlightedItem.current;
          const shouldSelectAnyItem =
            inputVal &&
            autoSelectItemOnTab &&
            allowAnyValue &&
            !currentHighlightedItem.current &&
            !manuallyHighlightingItemsFlag.current;

          // Allow auto-selecting of item on tab away
          if (shouldAutoSelectItemOnTab && currentHighlightedItem.current) {
            // In this case, if allowAnyValue is on, we don't want to add the item if it's actually match the item's label, to prevent strange behavior
            if (
              allowAnyValue &&
              currentHighlightedItem.current.label !== inputVal &&
              !manuallyHighlightingItemsFlag.current
            ) {
              setValue([...uniqueCurrentVal, customItem]);
            } else {
              manuallyHighlightingItemsFlag.current = false;
              setValue([...uniqueCurrentVal, currentHighlightedItem.current]);
              selectingOnTabRef.current = true;
            }

            setInputValue(isMultiSelect ? '' : formatInputValue(currentHighlightedItem.current));
            setOpen(false);
            break;
          }

          // Allow any item to be selected, even if nothing's in the list
          if (shouldSelectAnyItem) {
            setValue([...uniqueCurrentVal, customItem]);
            setInputValue(isMultiSelect ? '' : formatInputValue(customItem));
          }

          setOpen(false);
          break;
        }
        default: {
          // Clear the value if it's a single select and the user is typing
          if (hasInputValue && isSingleSelect && hasSelectedValue && isAlphaNumeric) {
            setValue([]);
            return;
          }
        }
      }
    },
    [
      allowAnyValue,
      autoSelectItemOnTab,
      formatInputValue,
      isMultiSelect,
      setInputValue,
      setValue,
      value,
      keyboard$
    ]
  );

  const inputGroupStyle = useMemo(
    () => ({
      ...(inputProps?.style || {}),
      backgroundColor: inputProps?.variant === 'primary' ? 'inherit' : undefined
    }),
    [inputProps?.style, inputProps?.variant]
  );

  const tagIconStyle = useMemo(
    () =>
      !isMultiSelect
        ? ({
            display: 'none'
          } as React.CSSProperties)
        : {},
    [isMultiSelect]
  );

  const tagGroupStyle: React.CSSProperties = useMemo(
    () => ({
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: '0.25em'
    }),
    []
  );

  /**
   * Custom Renderer to render a custom tag for the single select
   */
  const customRenderer = useCallback(() => {
    if (!isMultiSelect && value && value.length && !inputProps.isDisabled && !inputProps.isReadOnly) {
      return (
        <CloseButton
          aria-label={`Clear`}
          tabIndex={-1}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleRemove(value?.[0] || null);
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          type="button"
          data-testid={`autocomplete-clear-${name}`}
          size={inputProps?.variant === 'primary' ? 'md' : inputProps.size}
          style={{
            position: 'absolute',
            right: 0,
            zIndex: 4,
            backgroundColor: 'transparent'
          }}
        />
      );
    } else {
      return null;
    }
  }, [
    isMultiSelect,
    value,
    inputProps.isDisabled,
    inputProps.isReadOnly,
    handleRemove,
    name,
    inputProps?.variant,
    inputProps.size
  ]);

  /**
   * Tags for multi-select
   */
  const multiSelectTags = useMemo(() => {
    // Don't render tags for single select
    if (!isMultiSelect) {
      return null;
    }
    return (
      <>
        {(value || []).map((selection) => {
          // Don't render tag selection is null in value
          if (!selection) {
            return null;
          }

          const isDisabled = inputProps.isDisabled;
          const isDisabledOrReadonly = isDisabled || inputProps.isReadOnly;
          const isPrimary = inputProps?.variant === 'primary';
          const canClick = isMultiSelect && !isTypeahead;
          const canRemove = !inputProps.isDisabled && !inputProps.isReadOnly;
          const onClick = canClick ? onSingleTagReselect : undefined;
          const onDelete = canRemove ? () => handleRemove(selection) : undefined;
          const variant = isPrimary ? 'primary' : undefined;
          const size = inputProps?.size;

          return (
            <Tag
              role="button"
              aria-label={selection.label}
              data-testid={`autocomplete-${name}-${selection.id}`}
              key={selection.id}
              onDelete={onDelete}
              variant={variant}
              iconStyle={tagIconStyle}
              size={size}
              onClick={onClick}
              // note: defining inline here because we're in a useMemo & whole comp is re-rendered on value change anyway
              style={{
                marginRight: '0.25em',
                fontSize: '0.95rem',
                cursor: isDisabledOrReadonly ? 'not-allowed' : 'pointer',
                color: isDisabledOrReadonly ? 'var(--color-text-disabled)' : 'var(--color-text-primary)'
              }}
            >
              {selection.label ?? ''}
            </Tag>
          );
        })}
      </>
    );
  }, [
    value,
    inputProps.isDisabled,
    inputProps.isReadOnly,
    inputProps?.variant,
    inputProps?.size,
    tagIconStyle,
    isMultiSelect,
    isTypeahead,
    onSingleTagReselect,
    handleRemove,
    name
  ]);

  return (
    <PopoverTrigger
      popoverId={popoverIdRef.current}
      ariaLabel={ariaLabel || name}
      ref={useMergedRef(internalTriggerRef, forwardedRef)}
      ariaType="listbox"
      componentId={POPOVER.AUTOCOMPLETE}
      componentPropsUpdateMethod="merge"
      componentProps={outgoingComponentProps}
      side="bottom"
      align="start"
      width={props.width}
      minWidth={props.minWidth}
      sideOffset={props.sideOffset}
      alignOffset={props.alignOffset}
      autoSizeWidth={autoSizeWidthToTrigger === true ? false : autoSizeWidth}
      autoSizeWidthToTrigger={autoSizeWidthToTrigger}
      autoSizeHeight={true}
      height={1}
      minHeight={props.minHeight}
      trigger="none"
      closingStrategy="click-outside-ex-trigger"
      asChild={true}
      open={open}
      onOpenChange={setOpen}
      customTriggerSelector={`.autocomplete-group-${popoverIdRef.current}`}
      autoSizeTriggerResizePixelSensitivity={4}
      {...props}
    >
      <InputGroup
        {...inputProps}
        placeholder={isInputLoading ? 'Loading...' : inputProps.placeholder || ''}
        className={clsx(
          inputProps?.className,
          `autocomplete-group-${popoverIdRef.current}`,
          `autocomplete-group-${name}`
        )}
        style={inputGroupStyle}
        onFocus={onFocus}
        onClick={onFocusClick}
        onKeyDown={onKeyDown}
        value={inputValue}
        onChange={onInputValueChange}
        customRenderer={customRenderer}
        role="textbox"
        aria-label={name}
        tagGroupStyle={tagGroupStyle}
      >
        {multiSelectTags}
      </InputGroup>
    </PopoverTrigger>
  );
};

/**
 * Autocomplete Component
 * Note: Defined as a separate component to allow for ref forwarding AND to allow for TS generics
 */
export const Autocomplete = React.forwardRef(AutocompleteComponent) as <
  T extends ComboBoxDefaultValue = ComboBoxDefaultValue
>(
  props: AutocompleteProps<T> & React.RefAttributes<HTMLButtonElement>
) => JSX.Element;
