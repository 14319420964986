import {
  FC,
  useCallback,
  useMemo,
  MouseEventHandler,
  useState,
  useEffect,
  CSSProperties,
  useRef
} from 'react';
import { Box, Flex, Stack } from '@oms/shared-frontend/ui-design-system';
import { useComponentService } from '../hooks/component.service.hook';
import { VGridProps } from './vgrid.component';
import { AnyRecord } from '@oms/frontend-foundation';
import { useGridContainer } from '../hooks/grid.container.hook';
import { useActionsService } from '../hooks/action.events.hook';
import { ComponentLocation, ComponentLocationKeys, VComponent } from '../models/v.component.model';
import { ToolbarService } from '../services/toolbar.service';
import { ToolbarLocationKeys, ToolbarStrategy } from '../models/toolbar.model';
import { appWindow } from '@tauri-apps/api/window';
import { UnlistenFn } from '@tauri-apps/api/event';
import { isTauri } from '@valstro/workspace';

export type VGridActionsToolbar = {
  location: 'left' | 'right' | 'bottom';
  onToolbarHotkey?: VGridProps<AnyRecord>['onToolbarHotkey'];
};

const shouldRenderToolbar = (strat: ToolbarStrategy | undefined, comps: VComponent<AnyRecord>[]): boolean => {
  if (!strat) return false;
  switch (strat) {
    case 'visible':
      return true;
    case 'hidden':
      return false;
    case 'has-components':
      return !!comps?.length;
  }
};

const calcComponentLocation = (location: VGridActionsToolbar['location'], renderLocation: 'left' | 'right') =>
  location === 'bottom' && renderLocation === 'left'
    ? 'HorizontalToolbarLeft'
    : location === 'bottom' && renderLocation === 'right'
    ? 'HorizontalToolbarRight'
    : location === 'left' && renderLocation === 'left'
    ? 'LeftVerticalToolbar'
    : location === 'left'
    ? 'LeftVerticalToolbar'
    : location === 'right'
    ? 'RightVerticalToolbar'
    : 'RightVerticalToolbar';

const useToolbarStrategy = (
  toolbarLocation: VGridActionsToolbar['location'],
  renderLocation: 'left' | 'right'
) => {
  const location = calcComponentLocation(toolbarLocation, renderLocation);
  const container = useGridContainer();
  const [toolbarStrategy, setToolbarStrategy] = useState<ToolbarStrategy>();

  useEffect(() => {
    const toolbarService = container.resolve(ToolbarService);
    const sub = toolbarService.byLocation$(location).subscribe((strategy) => {
      setToolbarStrategy(strategy);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [location]);

  return toolbarStrategy;
};

export const VGridToolbar: FC<VGridActionsToolbar> = ({ location, onToolbarHotkey }) => {
  const container = useGridContainer();
  const actionService = useActionsService();
  const [hotkeyOverlay, setHotkeyOverlay] = useState(false);
  const tauriFocusSub = useRef<UnlistenFn>();

  useEffect(() => {
    if (isTauri()) {
      if (tauriFocusSub.current) {
        tauriFocusSub.current();
      }

      appWindow
        .onFocusChanged((e) => {
          if (!e.payload) {
            setHotkeyOverlay(false);
          }
        })
        .then((unsub) => {
          tauriFocusSub.current = unsub;
        });
    }

    const keydownCb: (e: KeyboardEvent) => any = (e) => {
      if (e.key === 'Control' && !hotkeyOverlay) {
        setHotkeyOverlay(true);
      }
    };

    const keyupCb: (e: KeyboardEvent) => any = (e) => {
      if (e.key === 'Control' && hotkeyOverlay) {
        setHotkeyOverlay(false);
      }
    };

    const blurCb: (e: FocusEvent) => any = (_e) => {
      setHotkeyOverlay(false);
    };

    document.addEventListener('keydown', keydownCb);
    document.addEventListener('keyup', keyupCb);
    document.addEventListener('blur', blurCb);

    return () => {
      document.removeEventListener('keydown', keydownCb);
      document.removeEventListener('keyup', keyupCb);
      document.removeEventListener('blur', blurCb);
      tauriFocusSub.current?.();
    };
  }, [hotkeyOverlay]);

  const horizToolbarRightComps = useComponentService(ComponentLocation.HorizontalToolbarRight);
  const horizToolbarLeftComps = useComponentService(ComponentLocation.HorizontalToolbarLeft);
  const leftVertComps = useComponentService(ComponentLocation.LeftVerticalToolbar);
  const rightVertComps = useComponentService(ComponentLocation.RightVerticalToolbar);
  const compsToDisplay = useMemo(
    () =>
      location === 'bottom'
        ? [horizToolbarLeftComps, horizToolbarRightComps]
        : location === 'left'
        ? [leftVertComps]
        : [rightVertComps],
    [horizToolbarLeftComps, horizToolbarRightComps, leftVertComps, location, rightVertComps]
  );
  const findComponentLocation: (renderLocation: 'left' | 'right') => ComponentLocationKeys = useCallback(
    (renderLocation: 'left' | 'right') => calcComponentLocation(location, renderLocation),
    [location]
  );

  const onToolbarClick: (renderLocation: 'left' | 'right') => MouseEventHandler<HTMLDivElement> = useCallback(
    (renderLocation: 'left' | 'right') => async () => {
      if (hotkeyOverlay) {
        const componentLocation = findComponentLocation(renderLocation);
        await onToolbarHotkey?.({
          location: componentLocation as ToolbarLocationKeys,
          actionService,
          container
        });

        // In the desktop app, when a new window pops up, we need the parent window to remove the overlay.
        const ctrlKeyupEvent = new KeyboardEvent('keyup', {
          key: 'Control',
          code: 'ControlLeft',
          keyCode: 17,
          charCode: 0,
          ctrlKey: true,
          bubbles: true
        });
        document.dispatchEvent(ctrlKeyupEvent);
      }
    },
    [findComponentLocation, onToolbarHotkey, actionService, container, hotkeyOverlay]
  );

  const leftmostToolbarStrategy = useToolbarStrategy(location, 'left');
  const rightmostToolbarStrategy = useToolbarStrategy(location, 'right');
  const shouldRender = [
    shouldRenderToolbar(leftmostToolbarStrategy, compsToDisplay[0]),
    shouldRenderToolbar(rightmostToolbarStrategy, compsToDisplay[1])
  ];

  const overlayStyles: CSSProperties = useMemo(
    () => ({
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: hotkeyOverlay ? 'absolute' : 'inherit',
      zIndex: hotkeyOverlay ? 2 : 'inherit'
    }),
    [hotkeyOverlay]
  );

  return (
    <Flex
      data-testid={location}
      align="center"
      justify="space-between"
      sx={{
        px: 2,
        backgroundColor: 'layout.level3'
      }}
      className="ag-toolbar vgrid-toolbar"
      role="toolbar"
      style={{
        borderBottom: '0',
        height: location === 'bottom' ? '44px' : '100%',
        flexDirection: location === 'bottom' ? 'row' : 'column',
        justifyContent: 'flex-start',
        width: location === 'bottom' ? undefined : '100px',
        display: shouldRender[0] || shouldRender[1] ? 'inherit' : 'none'
      }}
    >
      <Stack
        data-testid={`${location}-left`}
        onClick={onToolbarClick('left')}
        direction={location === 'bottom' ? 'horizontal' : 'vertical'}
        sx={{ height: 'full', width: 'full', alignItems: 'center', position: 'relative' }}
        spacing={2}
      >
        <Box as={'div'} className="overlay" style={overlayStyles} />
        {compsToDisplay[0].map((e) => e.component && <e.component {...e.props} key={e.id} />)}
      </Stack>
      {compsToDisplay?.length >= 2 && (
        <Stack
          data-testid={`${location}-right`}
          sx={{
            height: 'full',
            width: 'full',
            flexDirection: location === 'bottom' ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative'
          }}
          onClick={onToolbarClick('right')}
          direction={location === 'bottom' ? 'horizontal' : 'vertical'}
          spacing={2}
        >
          <Box as={'div'} className="overlay" style={overlayStyles} />
          {compsToDisplay[1].map((e) => e.component && <e.component {...e.props} key={e.id} />)}
        </Stack>
      )}
    </Flex>
  );
};
