import type { ColDef } from '@ag-grid-community/core';
import { t } from '@oms/codegen/translations';
import type { Maybe } from '@oms/shared/util-types';
import { sortingFunction } from '@oms/shared/util';
import type { ColumnBuilderCallback } from '@oms/frontend-vgrid';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import instrumentColumnRenderer from '../renderers/instrument-column-renderer';
import { instrumentCols } from '@app/common/grids/columns/instrument-cols';
import {
  sharedCurrencyCol,
  sharedDateTimeCol,
  sharedDecimalCol,
  sharedDefaultCol,
  sharedNumericCol,
  sharedQuantityCol
} from '@app/common/grids/columns/generic-cols';
import { sharedNameCol } from '@app/common/grids/columns/reference-data-cols';
import { sharedPositionsNumeric, sharedPositionsPrice } from '@app/common/grids/columns/positions-cols';
import type ServerSideRowDataHelper from '@app/data-access/services/trading/positions/common/tree-grid/helpers/server-side-row-data-helper.abstract.class';
import type { TreeDataSlice } from '@app/data-access/services/trading/positions/common/tree-grid/types/tree-data.types';
import { buildSetFilter } from '@app/data-access/services/trading/positions/common/tree-grid/util/filtering/grid-filter-values.util';

type PositionsColumn = ColumnBuilderCallback<TreeDataSlice<PositionRow>>;
type InstrumentColRecord = Record<'displayCode' | 'longName', PositionsColumn>;

type ServerSideColCreator = (dataHelper?: ServerSideRowDataHelper<PositionRow>) => PositionsColumn;
type InstrumentServerSideColCreator = (
  dataHelper?: ServerSideRowDataHelper<PositionRow>
) => InstrumentColRecord;

const [instrumentDisplayCode, instrumentLongName] = instrumentCols<TreeDataSlice<PositionRow>>({
  include: ['displayCode', 'longName']
});

const sortByAbsoluteValue: ColDef<TreeDataSlice<PositionRow>>['comparator'] = (a, b, _, __, isDescending) =>
  sortingFunction.number.byAbsoluteValue(isDescending ? 'descending' : 'ascending')(
    a as Maybe<number>,
    b as Maybe<number>
  );

export const defaultColumn: PositionsColumn = (c) => sharedDefaultCol(c);

export const id: PositionsColumn = (c) => sharedNameCol(c, 'id');

export const name: PositionsColumn = (c) => sharedNameCol(c, 'name');

// instrument.xxxxx ----- /

const instrumentShared: InstrumentColRecord = {
  displayCode: (c) =>
    instrumentDisplayCode(c)
      .field('instrument.mappings.displayCode')
      .header(t('app.common.instrument'))
      .shortHeader(t('app.common.instrument', { ns: 'short' }))
      .cell((cb) => cb.renderer(instrumentColumnRenderer)),
  longName: (c) =>
    instrumentLongName(c)
      .field('instrument.longName')
      .header(t('app.positions.instrument.name'))
      .shortHeader(t('app.positions.instrument.name', { ns: 'short' }))
      .cell((cb) => cb.renderer(instrumentColumnRenderer))
};

export const instrument: InstrumentColRecord = {
  displayCode: (c) => instrumentShared.displayCode(c).filter(true),
  longName: (c) => instrumentShared.longName(c).filter(true)
};

export const instrumentServerSide: InstrumentServerSideColCreator = (dataHelper) => ({
  displayCode: (c) =>
    instrumentShared.displayCode(c).pipe((c) =>
      buildSetFilter(c, dataHelper, {
        getValue: ({ instrument }) => instrument?.mappings?.displayCode ?? ''
      })
    ),
  longName: (c) =>
    instrumentShared.longName(c).pipe((c) =>
      buildSetFilter(c, dataHelper, {
        getValue: ({ instrument }) => instrument?.mappings?.displayCode ?? ''
      })
    )
});

// account.name ----- /

const accountNameShared: PositionsColumn = (c) =>
  sharedNameCol(c, 'account.name')
    .header(t('app.positions.account'))
    .shortHeader(t('app.positions.account', { ns: 'short' }));

export const accountName: PositionsColumn = (c) => accountNameShared(c).filter(true);

export const accountNameServerSide: ServerSideColCreator = (dataHelper) => (c) =>
  accountNameShared(c).pipe((c) =>
    buildSetFilter(c, dataHelper, { getValue: ({ account }) => account?.name ?? '' })
  );

// accountCurrency.id ----- /

const accountCurrencyIdShared: PositionsColumn = (c) =>
  sharedCurrencyCol(c, 'accountCurrency.id')
    .header(t('app.positions.accountCurrency'))
    .shortHeader(t('app.positions.accountCurrency', { ns: 'short' }));

export const accountCurrencyId: PositionsColumn = (c) => accountCurrencyIdShared(c).filter(true);

export const accountCurrencyIdServerSide: ServerSideColCreator = (dataHelper) => (c) =>
  accountCurrencyIdShared(c).pipe((c) =>
    buildSetFilter(c, dataHelper, { getValue: ({ accountCurrency }) => accountCurrency?.id ?? '' })
  );

// ----- /

export const quantity: PositionsColumn = (c) =>
  sharedQuantityCol(c, 'quantity')
    .header(t('app.positions.quantity'))
    .shortHeader(t('app.positions.quantity', { ns: 'short' }))
    .comparator(sortByAbsoluteValue);

// currency.id ----- /

const currencyIdShared: PositionsColumn = (c) =>
  sharedCurrencyCol(c, 'currency.id')
    .header(t('app.positions.currency'))
    .shortHeader(t('app.positions.currency', { ns: 'short' }))
    .width(80);

export const currencyId: PositionsColumn = (c) => currencyIdShared(c).filter(true);

export const currencyIdServerSide: ServerSideColCreator = (dataHelper) => (c) =>
  currencyIdShared(c).pipe((c) =>
    buildSetFilter(c, dataHelper, { getValue: ({ currency }) => currency?.id ?? '' })
  );

// ----- /

// TODO: Stopped doing i18n here in interest of time. Continue per examples above as time allows.

export const longPosition: PositionsColumn = (c) =>
  sharedNumericCol(c, 'longPosition')
    .header('Long Position')
    .shortHeader('LngPos')
    .comparator(sortByAbsoluteValue);

export const shortPosition: PositionsColumn = (c) =>
  sharedNumericCol(c, 'shortPosition')
    .header('Short Position')
    .shortHeader('ShrtPos')
    .comparator(sortByAbsoluteValue);

export const netPosition: PositionsColumn = (c) =>
  sharedPositionsNumeric(c, 'netPosition')
    .header('Net Position')
    .shortHeader('NetPos')
    .comparator(sortByAbsoluteValue);

export const absolutePosition: PositionsColumn = (c) =>
  sharedPositionsNumeric(c, 'absolutePosition').header('Position Absolute').shortHeader('AbsPos');

export const longExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'longExposure')
    .header('Long Exposure')
    .shortHeader('LngExp')
    .comparator(sortByAbsoluteValue);

export const shortExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'shortExposure')
    .header('Short Exposure')
    .shortHeader('ShrtExp')
    .comparator(sortByAbsoluteValue);

export const netExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'netExposure')
    .header('Net Exposure')
    .shortHeader('NetExp')
    .comparator(sortByAbsoluteValue);

export const combinedExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'combinedExposure')
    .header('Combined Exposure')
    .shortHeader('CmbExp')
    .comparator(sortByAbsoluteValue);

export const totalPNLToday: PositionsColumn = (c) =>
  sharedNumericCol(c, 'totalPNLToday')
    .header('Total PnL Today')
    .shortHeader('TotPnLToday')
    .minWidth(120)
    .comparator(sortByAbsoluteValue);

export const unrealizedPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'unrealizedPNL')
    .header('Unrealized PnL')
    .shortHeader('UReal')
    .comparator(sortByAbsoluteValue);

export const realizedPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'realizedPNL')
    .header('Realized PnL')
    .shortHeader('Real')
    .comparator(sortByAbsoluteValue);

export const realizedPNLToday: PositionsColumn = (c) =>
  sharedNumericCol(c, 'realizedPNLToday')
    .header('Realized PnL Today')
    .shortHeader('Realized')
    .comparator(sortByAbsoluteValue);

export const monthToDatePNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'monthToDatePNL')
    .header('MTD PnL')
    .shortHeader('MTDPnL')
    .comparator(sortByAbsoluteValue);

export const previousMonthPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'previousMonthPNL')
    .header('Prev MTD PnL')
    .shortHeader('PrevMTDPnL')
    .minWidth(120)
    .comparator(sortByAbsoluteValue);

export const quarterToDatePNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'quarterToDatePNL')
    .header('QTD PnL')
    .shortHeader('QTDPnL')
    .comparator(sortByAbsoluteValue);

export const previousQuarterPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'previousQuarterPNL')
    .header('Prev QTD PnL')
    .shortHeader('PrevQTDPnL')
    .minWidth(120)
    .comparator(sortByAbsoluteValue);

export const yearToDatePNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'yearToDatePNL')
    .header('YTD PnL')
    .shortHeader('YTDPnL')
    .comparator(sortByAbsoluteValue);

export const previousYearPNL: PositionsColumn = (c) =>
  sharedNumericCol(c, 'previousYearPNL')
    .header('Prev YTD PnL')
    .shortHeader('PrevYTDPnL')
    .minWidth(120)
    .comparator(sortByAbsoluteValue);

export const longPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'longPositionCost')
    .header('Long Position Cost')
    .shortHeader('LngCst')
    .comparator(sortByAbsoluteValue);

export const shortPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'shortPositionCost')
    .header('Short Position Cost')
    .shortHeader('ShrtCst')
    .comparator(sortByAbsoluteValue);

export const netPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'netPositionCost')
    .header('Net Position Cost')
    .shortHeader('NetCst')
    .comparator(sortByAbsoluteValue);

export const combinedPositionCost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'combinedPositionCost')
    .header('Combined Position Cost')
    .shortHeader('CmbCst')
    .comparator(sortByAbsoluteValue);

export const averagePrice: PositionsColumn = (c) =>
  sharedPositionsPrice(c, 'averagePrice').header('Avg. Price').shortHeader('AvgPx');

export const cost: PositionsColumn = (c) =>
  sharedNumericCol(c, 'cost').header('Position Cost').shortHeader('PosCst').comparator(sortByAbsoluteValue);

export const trueAveragePrice: PositionsColumn = (c) =>
  sharedPositionsPrice(c, 'trueAveragePrice').header('True Average Price').shortHeader('TruAvgPx');

export const valuationPrice: PositionsColumn = (c) =>
  sharedDecimalCol(c, 'valuationPrice').header('Valuation Price').shortHeader('ValPx');

export const positionValuationStrategy: PositionsColumn = (c) =>
  c
    .field('positionValuationStrategy')
    .header('Position Valuation Strategy')
    .shortHeader('ValStrat')
    .width(150)
    .minWidth(110);

export const lastMarkedDateTime: PositionsColumn = (c) =>
  sharedDateTimeCol(c, 'lastMarkedDateTime').header('Last Marked').shortHeader('LstMrk');

export const manualMarkEndDateTime: PositionsColumn = (c) =>
  sharedDateTimeCol(c, 'manualMark.endDateTime').header('Valuation Override End').shortHeader('ValOvrdEnd');

export const positionExposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'positionExposure')
    .header('Position Exposure')
    .shortHeader('PosExp')
    .comparator(sortByAbsoluteValue);

export const exposure: PositionsColumn = (c) =>
  sharedNumericCol(c, 'exposure').header('Exposure').shortHeader('Exp').comparator(sortByAbsoluteValue);

export const tradedToday: PositionsColumn = (c) =>
  sharedNumericCol(c, 'tradedToday').header('Traded Today').shortHeader('Traded').hide(true);

export const accountAccumulationFxRate: PositionsColumn = (c) =>
  sharedDecimalCol(c, 'accountAccumulationFxRate')
    .header('Account Accumulation Fx Rate')
    .shortHeader('AccFx');

export const estimatedCommission: PositionsColumn = (c) =>
  sharedPositionsNumeric(c, 'estimatedCommission')
    .header('Estimated Commission')
    .shortHeader('EstComm')
    .hide(true);
