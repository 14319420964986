import type { Optional } from '@oms/shared/util-types';
import { Result } from './result.class';

type AnyRecord = Record<string | number | symbol, unknown>;

export type PredicateFn<T> = (value: unknown) => value is T;

export type ObjectPredicateFn<T extends AnyRecord> = (value: AnyRecord) => value is T;

export type SerializerFn<T extends Object> = (value: T) => string;

export type DeserializerFn<T extends Object> = (serializedValue: string) => Result<T, Error>;

export type SerializationSetup<T extends Object> = {
  serializer: SerializerFn<T>;
  deserializer: DeserializerFn<T>;
};

export class TypedStorage implements Storage {
  protected storage: Storage;

  // 🏗️ Constructors ------------------------------------------------ /

  public constructor(storage: Storage) {
    this.storage = storage;
  }

  public static local(): TypedStorage {
    return new TypedStorage(localStorage);
  }

  public static session(): TypedStorage {
    return new TypedStorage(sessionStorage);
  }

  // 📢 Public ---------------------------------------------------- /

  // 🔤 String ----- /

  public getString(key: string): Optional<string> {
    return this.getItem(key) || undefined;
  }

  public setString(key: string, value: string): Optional<string> {
    const existing = this.getString(key);
    this.setItem(key, value);
    return existing;
  }

  // 🔢 Number ----- /

  public getNumber(key: string): Optional<number> {
    const value = this.getItem(key);
    if (!value) return undefined;
    const asNum = Number.parseFloat(value);
    if (Number.isNaN(asNum)) return undefined;
    return asNum;
  }

  public setNumber(key: string, value: number): Optional<number> {
    const existing = this.getNumber(key);
    this.setItem(key, value.toString());
    return existing;
  }

  // 🔷 Boolean ----- /

  public getBool(key: string): Optional<boolean> {
    const value = this.getItem(key);
    if (!value) return undefined;
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  }

  public setBool(key: string, value: boolean): Optional<boolean> {
    const existing = this.getBool(key);
    this.setItem(key, value ? 'true' : 'false');
    return existing;
  }

  // 📦 Object ----- /

  protected predicateMap = new Map<string, Function>();

  public getObject<T extends AnyRecord = AnyRecord>(
    key: string,
    predicate?: ObjectPredicateFn<T>
  ): Optional<T> {
    const value = this.getItem(key);
    if (!value) return undefined;
    const parsed = this.parse(value);
    if (!parsed) return undefined;
    const predicateFn = predicate || this.predicateMap.get(key);
    if (!predicateFn) return parsed as T;
    return (predicateFn as ObjectPredicateFn<T>)(parsed) ? parsed : undefined;
  }

  public setObject<T extends AnyRecord>(
    key: string,
    value: T,
    predicate?: ObjectPredicateFn<T>
  ): Optional<AnyRecord> {
    const existing = this.getObject(key);
    this.setItem(key, JSON.stringify(value));
    if (predicate) this.predicateMap.set(key, predicate);
    return existing;
  }

  protected parse(text: string): Optional<AnyRecord> {
    try {
      const parsed = JSON.parse(text) as unknown;
      if (typeof parsed !== 'object' || parsed === null) return undefined;
      return parsed as AnyRecord;
    } catch (_) {
      return undefined;
    }
  }

  // 📦 Array ----- /

  public getArray<T>(
    key: string,
    predicate?: PredicateFn<T>,
    checkAll?: boolean
  ): Optional<T[]> {
    const value = this.getItem(key);
    if (!value) return undefined;
    const parsed = this.parse(value);
    const arr = Array.isArray(parsed) ? (parsed as unknown[]) : undefined;
    if (!arr) return undefined;
    const predicateFn = predicate || this.predicateMap.get(key);
    if (!predicateFn || !arr.length) return arr as T[];
    if (!checkAll) {
      const firstValue = arr[0];
      return (predicateFn as PredicateFn<T>)(firstValue) ? arr as T[] : undefined;
    }
    for (let item of arr) {
      if (!(predicateFn as PredicateFn<T>)(item)) return undefined;
    }
    return arr as T[];
  }

  public setArray<T>(
    key: string,
    value: T[],
    predicate?: PredicateFn<T>
  ): Optional<T[]> {
    const existing = this.getArray<T>(key);
    this.setItem(key, JSON.stringify(value));
    if (predicate) this.predicateMap.set(key, predicate);
    return existing;
  }

  // 🏛️ Class ----- /

  protected serializationSetupMap = new Map<string, SerializationSetup<any>>();

  public getClass<T extends Object>(
    key: string,
    deserializer?: DeserializerFn<T>
  ): Result<T, Error> {
    const value = this.getItem(key);
    if (!value) return Result.failure(new Error(`No value was stored at "${key}"`));
    const deserializerFn = deserializer || this.serializationSetupMap.get(key)?.deserializer;
    if (!deserializerFn) return Result.failure(new Error(`No deserializer function was found for "${key}"`));
    return (deserializerFn as DeserializerFn<T>)(value);
  }

  public setClass<T extends Object>(
    key: string,
    value: T,
    setup: SerializationSetup<T>
  ): Optional<T> {
    this.serializationSetupMap.set(key, setup);
    const existing = this.getClass<T>(key, setup.deserializer);
    this.setItem(key, setup.serializer(value));
    return existing.mapTo(
      (value) => value,
      (_) => undefined,
    );
  }

  public updateClass<T extends Object>(
    key: string,
    value: T,
    serializer?: SerializerFn<T>
  ): Result<T, Error> {
    const existing = this.getClass<T>(key);
    if (existing.isFailure()) return Result.failure(existing.value);
    const serializerFn = serializer || this.serializationSetupMap.get(key)?.serializer;
    if (!serializerFn) return Result.failure(new Error(`No serializer function was found for "${key}"`));
    this.setItem(key, serializerFn(value));
    return existing;
  }

  // 📢 Public (passthrough) --------------------------------------- /
  // Note: This section is passthrough so this can implement `Storage` and be use as a `Storage` object.

  public get length(): number {
    return this.storage.length;
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public removeItem(key: string): void {
    if (this.predicateMap.has(key)) {
      this.predicateMap.delete(key);
    }
    if (this.serializationSetupMap.has(key)) {
      this.serializationSetupMap.delete(key);
    }
    return this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }

  public key(index: number): string | null {
    return this.storage.key(index);
  }
}
