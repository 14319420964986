import { createBroadcastSignal } from '@oms/shared-frontend/rx-broadcast';
import type { Signal } from '@oms/shared-frontend/rx-broadcast';
import { DEFAULT_DATA_ACCESS_STATE as initalState } from '@app/common/data-access/data-access.contracts';
import type { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { ProcessState } from './process-id.subject';
import { inject, singleton } from 'tsyringe';

/**
 * Signal for DataAccessState
 *
 * Used to broadcast the current state of data access (graphql, rest, etc)
 * and to listen for changes to the DataAccessState
 *
 * Used primarily at the framework level to broadcast the current state of data access
 * @see app.stream.ts
 * @see plugin-data-access
 *
 * @usage
 * ```ts
 * const dataAccessSignal = container.resolve(DataAccessSignal);
 * const subscription = dataAccessSignal.$.subscribe((state) => {
 *  console.log('Data access state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(DataAccessSignal) private dataAccessSignal: DataAccessSignal) {
 *  const subscription = this.dataAccessSignal.$.subscribe((state) => {
 *    console.log('Data access state changed', state);
 *  });
 * }
 */
@singleton()
export class DataAccessSignal {
  public signal: Signal<DataAccessState>;
  constructor(@inject(ProcessState) private processState: ProcessState) {
    this.signal = createBroadcastSignal<DataAccessState>(
      `${this.channelName}-${this.processState.LEADER_PROCESS_ID}`,
      this.DEFAULT_STATE,
      {
        initialize$: this.processState.isLeaderProcess$,
        initializeOnce: false
      }
    );
  }

  public get channelName() {
    return 'dataAccess';
  }

  public get DEFAULT_STATE() {
    return initalState;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE);
  }
}
